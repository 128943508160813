import React, { useEffect } from "react"
import { Col, Row } from "react-bootstrap"
import Layout from "../components/layout"
import Seo from "../components/seo"
import party from "party-js"

const Success = () => {
  useEffect(() => {
    party.confetti(document.querySelector('.container'), {
      count: party.variation.range(20, 60),
      size: party.variation.range(0.8, 1.2),
    })
  }, [])

  return (
    <Layout>
      <Seo title="Payment Successful" />
      <div className="container text-center py-5 text-white">
        <Row className="justify-content-center">
          <Col md="12" className="col-8">
            <h1 className="bank-font">Successful payment, Thank You!</h1>
          </Col>
          <Col
            md="5"
            className="col-9 d-flex align-items-center justify-content-around"
          >
            <p className="aramaic h1" style={{ fontSize: 60 }}>
              ܬܲܘܕܝܼ
            </p>
          </Col>
          <Col md="12">
            <hr className="w-100" />
          </Col>
          <Col md="10">
            <p className="h5 gold-text">
              You should be receiving an email receipt very soon, and we will
              get in touch with you as soon as your order is ready to be shipped
              with shipping and tracking information.
            </p>
          </Col>
        </Row>
      </div>
    </Layout>
  )
}

export default Success
